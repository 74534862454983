import * as React from 'react';
import { type DataGridPro as MuiDataGridPro } from 'src/data_grid_pro_v7';

/**
 * DataGridPro is a fast and extendable data table and data grid.
 *
 * > ⚠️ *Warning*: This is a React.lazy component that must be wrapped with `React.Suspense`.
 */
export const DataGridProLazy = React.lazy<typeof MuiDataGridPro>(() =>
  import('src/browser.data_grid_pro_v7').then((module) => ({
    default: module.DataGridPro,
  }))
);
