import * as React from 'react';
import { type DataGridPremium as MuiDataGridPremium } from 'src/data_grid_premium';

/**
 * DataGridPremium is a fast and extendable data table and data grid.
 *
 * > ⚠️ *Warning*: This is a React.lazy component that must be wrapped with `React.Suspense`.
 */
export const DataGridPremiumLazy = React.lazy<typeof MuiDataGridPremium>(() =>
  import('src/browser.data_grid_premium').then((module) => ({
    default: module.DataGridPremium,
  }))
);
